.claim {
    padding-top: 156px;
    padding-bottom: 100px;
    .claim-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;

        h6 {
            color: #FFF;
            font-size: 22px;
            font-weight: 700;
            line-height: 100%;
            letter-spacing: 0.44px;
            text-transform: uppercase;
        }

        .btn-claim {
            border-radius: 5px;
            background: #FF0083;
            border: none;
            color: #FFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
            text-transform: uppercase;
            padding: 12px 25px;
        }

       
    }
    .bottom-table{
        .table-responsive{
            background: rgba(17, 19, 22, 0.60);
            backdrop-filter: blur(3px);
            border: 1px solid #000;
            border-radius: 5px;
            table{
                th{
                    border: none;
                    padding: 28px 30px;
                    color: #FF0083;
                    font-size: 14px;
                    font-weight: 700;
                    line-height: 24px;
                    letter-spacing: 0.28px;
                    text-transform: uppercase;
                    vertical-align: middle;
                    white-space: nowrap;
                    background-color: transparent;
                }
                td{
                    color: #FFF;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                    padding: 28px 30px;
                    vertical-align: middle;
                    border-top: 1px solid rgba(255, 255, 255, 0.05);
                    white-space: nowrap;
                    background-color: transparent;
                }
            }
        }
        .btn-claim{
            border-radius: 5px;
            background: #FF0083;
            border: none;
            max-width: 93px;
            width: 100%;
            padding: 12px;
            color: #FFF;
            font-size: 14px;
            font-weight: 500;
            line-height: 120%;
            text-transform: uppercase;
            &.disable{
                background: #30071E !important;
            }
            &.claimed{
                border: 1px solid #FF0083 !important;
                background-color: transparent !important;
            }

        }
        
    }
    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 14px 25px;

        .left-f {
            h6 {
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 100%;
                color: #767676;
            }
        }

        .right-f {
            .page-link {
                border: none;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
                background-color: var(--login-bg);

                &.active {
                    background: #FF0083;
                    border-radius: 5px;
                    color: #fff;
                }
                
            }


            .page-item:first-child .page-link {
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 100%;
                color: #BFC3C7;
            }
            .page-item.active .page-link{
                background: #FF0083 !important;
                border-radius: 5px !important;
                color: #fff !important;
            }
            .page-link:focus{
                box-shadow: none !important;
            }
        }
    }
}

.custom-container{
    max-width: 1220px;
    width: 100%;
    margin: 0 auto;
    padding-left: 15px;
    padding-right: 15px;
}

@media (max-width:600px){
    .accmblview {
        display: none !important;
        .accmblviewhead {
            color: var(--datepicker-cell-text);
            font-size: 14px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.28px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 8px;
            padding: 25px 28px;

            .arrowsacc {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        .accordion {
            border: none !important;
            background: transparent !important;
            box-shadow: none !important;
            outline: none !important;
            border-radius: none !important;

            .accordion-button::after {
                // background: url("../../Assests/accarrowdown.svg");
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                width: 8.571px;
                height: 5.143px;
            }

            .accordion-item {
                border: none !important;
                background: transparent !important;
                box-shadow: none !important;
                outline: none !important;
                border-radius: none !important;

                .accordion-header {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;

                    .accordion-button {
                        border-top: 1px solid var(--table-border-top);
                        border-bottom: 1px solid var(--table-border-top);
                        background: var(--login-bg);
                        padding: 22px 25px;

                        &:focus {
                            box-shadow: none;
                        }

                        .accheadermain {
                            display: flex;
                            justify-content: flex-start;
                            align-items: center;
                            gap: 12px;

                            .accmainimage {
                                width: 30px;
                                height: 30px;
                                border-radius: 60px;
                                background: rgba(0, 0, 0, 0.04);
                                object-fit: cover;
                                object-position: center;
                                display: flex;
                                align-items: center;

                                .accinnerimg {
                                    width: 100%;
                                    height: 100%;
                                    border-radius: 60px;
                                    background: rgba(0, 0, 0, 0.04);
                                    object-fit: cover;
                                    object-position: center;
                                }
                            }

                            .acctext {
                                color: var(--common-text1);
                                font-size: 14px;
                                font-weight: 500;
                            }
                        }
                    }
                }

                .accordion-body {
                    border: none !important;
                    background: transparent !important;
                    box-shadow: none !important;
                    outline: none !important;
                    border-radius: none !important;
                    padding: 20px 25px;

                    .acctexts {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 40px;

                        &:last-child {
                            margin-bottom: 0px;
                        }

                        .textleft {
                            font-size: 14px;
                            color: var(--common-text1);
                            font-weight: 500;
                            line-height: 100%;
                            letter-spacing: 0.28px;
                        }

                        .textright {
                            color: var(--td-text);
                            font-size: 14px;
                            line-height: 24px;
                            letter-spacing: 0.1px;
                        }
                    }
                }
            }
        }
    }
    .accmblview .accordion .accordion-item .accordion-header .accordion-button{
        box-shadow: none !important;
    }
    .claim .footer-content{
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        display: none;
    }
}
.noor{
    border: 1px solid #FF0083 !important;
    background-color: transparent !important;

}