.main-banner{
    position: relative;
    z-index: 99;
    min-height: 100vh;
    
    .banner-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }
    .banner-content{
        max-width: 546px;
        width: 100%;
        margin: 0 auto;
        padding-top: 147px;
        .upper-content{
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-radius: 15px;
            border: 1px solid #161516;
            background: rgba(17, 19, 22, 0.30);
            backdrop-filter: blur(3px);
            padding: 20px;
            margin-bottom: 10px;
            .left{
                display: flex;
                align-items: center;
                gap: 11px;
                
                .text{
                    h6{
                        color: #FFF;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                        margin-bottom: 5px;
                    }
                    p{
                        color: #717171;
                        font-size: 14px;
                        font-weight: 700;
                        line-height: 100%;
                        letter-spacing: 0.1px;
                    }
                }
            }
            .right{
                h6{
                    color: #FFF;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                    margin-bottom: 8px;
                }
                p{
                    color: #28E664;
                    font-size: 14px;
                    font-weight: 500;
                    line-height: 100%;
                    letter-spacing: 0.1px;
                }
                .red{
                    color: #f41e5e;
                }
            }
        }
        .bottom-content{
            border-radius: 15px;
            border: 1.5px solid rgba(255, 255, 255, 0.05);
            background: rgba(17, 19, 22, 0.30);
            backdrop-filter: blur(3px);
            padding: 20px;
            .main-head{
                color: #FFF;
                text-align: start;
                font-size: 24px;
                font-weight: 700;
                line-height: 30px;
                margin-bottom: 15px;
            }
            .parent-box{
                position: relative;
                .swap-arrow{
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                }
            }
            .inner-box{
                border-radius: 10px;
                border: 1px solid #161516;
                background: #0A0809;
                padding: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 5px;
                .left{
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    max-width: 110px;
                    width: 100%;
                    .dropdown{
                        width: 100%;
                        .dropdown-toggle{
                            border-radius: 40px;
                            border: 1px solid rgba(255, 255, 255, 0.03);
                            background: rgba(255, 255, 255, 0.03);
                            padding: 6px;
                            padding-right: 10px;
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            width: 100%;
                            
                            &::after{
                                display: none;
                            }
                        }
                        .dropdown-menu{
                            border-radius: 10px;
                            border: 1px solid #161516;
                            background: #0A0809;
                            padding: 15px 9px;
                            min-width: 90px;
                            width: 100%;
                            a{
                                color: #fff;
                                padding: 0;
                                img{
                                    width: 30px;
                                    height: 30px;
                                    margin-right: 5px;

                                }
                            }
                        }
                    }
                    .text{
                        h6{
                            color: #FFF;
                            font-size: 14px;
                            font-weight: 700;
                            line-height: 100%;
                            letter-spacing: 0.1px;
                        }
                    }
                }
                .right{
                    h6{
                        color: #717171;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 100%;
                        text-align: right;
                    }
                    // p{
                    //     color: rgba(255, 255, 255, 0.20);
                    //     font-size: 28px;
                    //     font-weight: 700;
                    //     line-height: 120%;
                    //     text-align: end;
                    // }
                    input{
                      
                        color: #fff;
                        font-size: 28px;
                        font-weight: 700;
                        line-height: 120%;
                        text-align: end;
                        background-color: transparent;
                        border: none;
                        max-width: 200px;
                        width: 100%;
                        &::placeholder{
                            color: rgba(255, 255, 255, 0.20);
                        }
                    }
                }
            }
         
        }
        .bottom-text{
            margin-top: 20px;
            .inner-text{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 10px;
                p{
                    color: #858485;
                    text-align: center;
                    font-size: 14px;
                    font-weight: 500;
                }
                h6{
                    color: #FFF;
                    text-align: right;
                    font-size: 14px;
                    font-weight: 500;
                }
            }
            .inner-text-bold{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                h6{
                    color: #FFF;
                    text-align: right;
                    font-size: 25px;
                    font-weight: 500;
                }
            }
        }
        .btn-buy{
            border-radius: 5px;
            background: #30071E;
            margin-top: 15px;
            width: 100%;
            padding: 15px 16px;
            color: #6E5161;
            text-align: center;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            border: none;
        }
    }

}
.newClasss {
    border-radius: 5px;
    background: #FF0083;
    box-shadow: 0px 7px 14px 0px rgba(255, 0, 131, 0.2);
    border: none;
    padding: 15px 16px;
    color: #FFF;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
}

.suxvsvsyucsusc{
    width: 30px;
    height: 30px;
}