.login-screen {
    position: relative;
    z-index: 99;
    min-height: 100vh;

    .login-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .upper-logo {
        padding-top: 30px;
        margin-left: 110px;
    }

    .bottom-card {
        border-radius: 15px;
        border: 1.5px solid rgba(255, 255, 255, 0.05);
        background: rgba(17, 19, 22, 0.30);
        backdrop-filter: blur(3px);
        padding: 35px 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 542px;
        width: 100%;
        margin: 0 auto;
        margin-top: 150px;

        h6 {
            color: #fff;
            font-size: 22px;
            font-weight: 700;
            line-height: 110%;
            margin-top: 50px;
            margin-bottom: 40px;
        }

        .option-field {
            margin-bottom: 41px;

            input {
                border-radius: 5px;
                border: 1px solid #161516;
                background: #0A0809;
                padding: 18px 20px;
                color: rgba(255, 255, 255, 0.20);
                font-size: 16px;
                font-weight: 500;
                line-height: 130%;
                width: 100%;
                color: #fff !important;
                &::placeholder{
                 
                    color: rgba(255, 255, 255, 0.20);
                }
            }
        }

        .btn-continue {
            border-radius: 8px;
            background: #FF0083;
            padding: 14px;
            width: 100%;
            border: none;
            color: #FFF;
            font-size: 16px;
        }
    }
}

.connectwallet-modal {
    background: rgba(0, 0, 0, 0.85);
backdrop-filter: blur(4px);
    .modal-dialog {
        max-width: 652px;
    }

    .modal-content {
        border-radius: 12px;
        border: 1px solid #171717;
        background: #080808;
        backdrop-filter: blur(7.5px);
        padding: 10px;
    }
    .modal-header{
        border-radius: 8px;
        border: 1px solid #171717;
        padding: 12px 10px;
        justify-content: space-between;
        .modal-title{
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            line-height: 110%;
        }
        .btn-close{
            background: url("../../assets/close-circle.svg");
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            border: none;
            padding: 15px;
        }
    }
    .modal-body{
        padding: 12px 10px;
        .twice-btns{
            display: flex;
            justify-content: space-between;
            align-items: center;
            button{
                flex: 0 0 49%;
                background-color: transparent;
                border-radius: 10px;
                border: 1px solid #171717;
                height: 209px;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                transition: 0.3s linear;
                h6{
                    color: #FFF;
                    text-align: center;
                    font-size: 20px;
                    font-weight: 500;
                }
                &:hover{
                    background: rgba(255, 0, 131, 0.10);
                    border: 1px solid transparent;
                }
            }
        }
        .buy-modal{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            p{
                color: #FFF;
                text-align: center;
                font-size: 16px;
                line-height: 110%;
                margin-top: 20px;
                margin-bottom: 30px;
                max-width: 433px;
                width: 100%;
                span{
                    color: #FF0083;
                }
            }
            .btn-common{
                border-radius: 5px;
                background: #FF0083;
                box-shadow: 0px 7px 14px 0px rgba(255, 0, 131, 0.20);
                border: none;
                padding: 15px 16px;
                color: var(--white, #FFF);
                text-align: center;
                font-size: 16px;
                font-weight: 700;
                line-height: 24px;
                width: 100%;
            }
        }
    }
}
.buy-modal{
    .modal-dialog{
        max-width: 508px;
    }
}

@media (max-width:600px){
    .login-screen .bottom-card{
        padding: 20px;
    }
    .login-screen .bottom-card h6{
        font-size: 16px;
        text-align: center;
    }
    .scvtsvctsc{
        max-width: 123px;
        width: 100%;
    }
    .connectwallet-modal .modal-body .twice-btns{
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
    }
    .connectwallet-modal .modal-body{
        padding: 12px 0;
    }
    .login-screen .upper-logo{
        margin-left: 0;
        text-align: center;
    }
}


.newClass {
    border-radius: 5px;
    background: #30071E;
    box-shadow: 0px 7px 14px 0px rgba(255, 0, 131, 0.2);
    border: none;
    padding: 15px 16px;
    color: #FFF;
    color: var(--white, #FFF);
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    width: 100%;
}