.main-navbar {
    position: absolute;
    top: 30px;
    left: 0;
    z-index: 30;
    width: 100%;

    ul {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .nav-item {
            .nav-link {
                color: #FFF;
                font-size: 16px;
                font-weight: 500;
                line-height: 100%;
                padding: 0;
                padding-right: 40px;
                transition: 0.3s linear;

                &:hover {
                    color: #FF0083;
                }
            }
        }
    }

    .connect-btn {
        border-radius: 5px;
        border: 1px solid #FF0083;
        background: #030303;
        padding: 14px 20px;
        color: #FF0083;
        font-size: 16px;
        font-weight: 500;
        line-height: 120%;
    }
}

@media (max-width:600px) {
    .offcanvas{
        width: 100% !important;
    }
    .main-navbar ul {
        position: static;
        transform: unset;
    }

    .main-navbar ul .nav-item .nav-link {
        padding: 15px 0;
        text-align: center;
    }

    .main-navbar .connect-btn {
        display: block;
        margin: 0 auto;
    }

    .navbar-collapse {
        border-radius: 15px;
        border: 1px solid #161516;
        background: rgba(17, 19, 22, 0.30);
        backdrop-filter: blur(3px);
        margin-top: 25px;
        padding: 15px;
    }
    .connectwallet-modal .modal-body .twice-btns button{
        padding: 30px 0;
    }
}


.offcanvas {
    background: #030303 !important;

    .offcanvas-header {
        .btn-close {
            background: url("../../../assets/close-circle-new.svg") !important;
            background-size: contain !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            border: none !important;
        }
    }

    .offcanvas-body {
        a {
            padding: 20px 25px;
            color: #FFF;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            display: block;
            border-left: 6px solid transparent;
            &:hover{
                border-left: 6px solid #FF0083;
                background: #12080D;
            }
            &:focus{
                border-left: 6px solid #FF0083;
                background: #12080D;
            }
            &:active{
                border-left: 6px solid #FF0083;
                background: #12080D;
            }
        }

        button {
            border-radius: 5px;
            border: 1px solid #FF0083;
            background: #080808;
            padding: 17px 20px;
            width: 100%;
            color: #FF0083;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-top: 37px;
            /* 19.2px */
        }
    }
}